<template>
  <!--====== APPIE COUNTER PART START ======-->

  <section class="appie-counter-area pt-90 pb-190" id="mycounter">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">

          <div class="appie-section-title">
            <h3 class="appie-title">{{title}}</h3>
            <p>{{description}}</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 col-md-6">
          <div class="appie-single-counter mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="200ms">
            <div class="counter-content">
              <div class="icon">
                <img src="@/assets/images/icon/app_img.png" style="width: 100px" alt="">
              </div>
              <h3 class="title">
                                <span class="counter-item">
                                    <span v-if="showCounters">
                                        <coun-down-com :endVal="appCount" />
                                    </span>

                                  <!-- <template v-else>0</template> -->
                                </span>{{appCntValK}}</h3>
              <p>Apps Created</p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="appie-single-counter mt-30 item-2 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="400ms">
            <div class="counter-content">
              <div class="icon">
                <img src="@/assets/images/icon/version.png" style="width: 100px" alt="">
              </div>
              <h3 class="title">
                                <span class="counter-item">
                                    <span v-if="showCounters">
                                        <coun-down-com :endVal="verCount" />
                                    </span>

                                </span>{{verCntValK}}</h3>
              <p>Created Version</p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="appie-single-counter mt-30 item-3 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="600ms">
            <div class="counter-content">
              <div class="icon">
                <img src="@/assets/images/icon/developer.png" style="width: 100px" alt="">
              </div>
              <h3 class="title">
                                <span class="counter-item">
                                     <span v-if="showCounters">
                                        <coun-down-com :endVal="devCount" />
                                    </span>
                                </span>{{devCntValK}}</h3>
              <p>Registered Developers</p>
            </div>
          </div>
        </div>

      </div>
    </div>


  </section>

  <!--====== APPIE COUNTER PART ENDS ======-->
</template>

<script>

import * as commonLib from '@/assets/js/common'


import CounDownCom from '../Helper/CounDownCom.vue';
export default {
  components: {
    CounDownCom
  },
  props:{
    title:{
      type:String,
    },
    description:{
      type:String,
    },
  },
  data() {
    return {
      showCounters: false,
      counterPositions:null,

      appCount:0,
      devCount:0,
      verCount:0,

      appCntValK:'',
      devCntValK:'',
      verCntValK:''

    };
  },
  created() {
    this.getSwingInfoCnt();
  },
  mounted() {
    var rec = document.getElementById('mycounter')
    var currentPosition = rec.offsetTop - document.body.scrollTop;
    this.counterPositions=currentPosition
    window.addEventListener('scroll',()=>{
      var currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop;
      if(this.counterPositions !== null){
        if(currentScrollPosition + 500>this.counterPositions){
          this.examplem()
        }
      }

    })


  },
  methods: {
    examplem(){
      this.showCounters = true;
      this.counterPositions=null
    },

    async getSwingInfoCnt(){
      let me = this;

      const url = '/main/get_swing_info_list'
      // const url = `http://hustay.asuscomm.com:8803/main/get_swing_info_list`

      let paramMap = {}
      // eslint-disable-next-line no-return-await
      return await commonLib.swingApiFn(url, 'get', paramMap)
          .then(resultData=>{
            let result = resultData.data;
            if(result && result.swingInfoList && result.swingInfoList.length>0){
              result.swingInfoList.forEach(infoOb => {
                let lp_val = parseInt(infoOb.code_value);
                let lp_str = '';
                if(lp_val>1000){lp_val = parseInt(lp_val/1000);lp_str='k+';}
                if(infoOb.code_key === "APP_COUNT"){
                  me.appCount = lp_val;
                  me.appCntValK = lp_str;
                }else  if(infoOb.code_key === "DEV_COUNT"){
                  me.devCount = lp_val;
                  me.devCntValK = lp_str;
                }else  if(infoOb.code_key === "VER_COUNT"){
                  me.verCount = lp_val;
                  me.verCntValK = lp_str;
                }

              })
            }

          });
    }
  }

}
</script>

<style>


</style>
