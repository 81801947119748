<template>
      <!--====== APPIE BLOG 3 PART START ======-->
    
    <section class="appie-blog-3-area pt-90 pb-100">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="appie-section-title text-center">
                        <h3 class="appie-title">Download Our Website to App
                          Converter App Today!</h3>
                        <p>Stay updated on app trends with our blogs</p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6" v-for="(blog,index) in blogs" :key="index">
                    <div class="appie-blog-item-3 mt-30" >
                        <div class="thumb">
                            <img :src="blog.img" alt="">
                        </div>
                        <div class="content">
                            <h5 class="title"><a :href="blog.link" target="_blank">{{blog.title}}</a></h5>
<!--                            <div class="meta-item">-->
<!--                                <ul>-->
<!--                                    <li><i class="fal fa-clock"></i> {{blog.date}}</li>-->
<!--                                    <li><i class="fal fa-comments"></i> {{blog.comment_date}}</li>-->
<!--                                </ul>-->
<!--                            </div>-->
                        </div>
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="blog-btn text-center mt-60">
                        <a href="https://www.swing2app.com/blog" target="_blank" class="main-btn">
                          View All Posts <i class="fal fa-arrow-right"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
    
    <!--====== APPIE BLOG 3 PART ENDS ======-->
</template>

<script>
export default {
    props: {
        blogs:{
            type: Array,
            default:()=>{
                return []
            }
        }
    }

}
</script>

<style>

</style>