<template>
  <!--====== APPIE FEATURES PART START ======-->
    <section class="appie-features-area " id="features">
        <div class="container">
            <div class="row align-items-end">
              <div class="col-lg-6 col-md-8">
                <div class="appie-section-title">
                  <h3 class="appie-title">Special Features of Swing2App's Web App</h3>
                  <p>
                    Swing2App's web app does more than just display a website.<br>
                    It provides users with more convenient services and various features.<br>
                    Check out the special features that enable native functionality beyond the website.<br>
                  </p>

                </div>
              </div>
              <div class="col-lg-6 col-md-4">
              </div>
            </div>
            <div class="row align-items-center">
                <div class="col-lg-3">
                    <div class="appie-features-tabs-btn">
                        <div class="nav flex-column nav-pills"  role="tablist" aria-orientation="vertical">

                            <a @click.prevent="selectFeature('setting')" class="nav-link" :class="[selectedTab==='setting'?'active':'']" data-toggle="pill"  role="tab" aria-controls="v-pills-home" aria-selected="true"><i class="far fa-bell"></i> Push Notification</a>
                            <a @click.prevent="selectFeature('toolbar')" class="nav-link" :class="[selectedTab==='toolbar'?'active':'']"  data-toggle="pill" role="tab" aria-controls="v-pills-profile" aria-selected="false"><i class="fas fa-bars"></i> Toolbar</a>

                            <a @click.prevent="selectFeature('api-support')" class="nav-link" :class="[selectedTab==='api-support'?'active':'']"  data-toggle="pill" role="tab" aria-controls="v-pills-profile" aria-selected="false"><i class="fas fa-code"></i> API Support</a>
                            <a @click.prevent="selectFeature('admob')" class="nav-link" :class="[selectedTab==='admob'?'active':'']"  data-toggle="pill" role="tab" aria-controls="v-pills-profile" aria-selected="false"><i class="fas fa-ad"></i> Admob</a>

                            <a @click.prevent="selectFeature('notice')" class="nav-link" :class="[selectedTab==='notice'?'active':'']"  data-toggle="pill"  role="tab" aria-controls="v-pills-messages" aria-selected="false"><i class="far fa-link"></i> Custom Menu</a>
                            <a @click.prevent="selectFeature('bookmark')" class="nav-link" :class="[selectedTab==='bookmark'?'active':'']" data-toggle="pill"  role="tab" aria-controls="v-pills-settings" aria-selected="false"><i class="far fa-bookmark"></i> Bookmark</a>
                            <a @click.prevent="selectFeature('push-history')" class="nav-link" :class="[selectedTab==='push-history'?'active':'']" data-toggle="pill"  role="tab" aria-controls="v-pills-home" aria-selected="true"><i class="fas fa-history"></i> Push History</a>
                            <a @click.prevent="selectFeature('lock')" class="nav-link" :class="[selectedTab==='lock'?'active':'']" data-toggle="pill"  role="tab" aria-controls="v-pills-settings" aria-selected="false"><i class="far fa-code-merge"></i> Real time update</a>
                        </div>
                    </div>  
                </div>
                <div class="col-lg-9">
                    <div class="tab-content" >
                            <div :class="[selectedTab==='setting'?'active show':'']"  class="tab-pane fade "  role="tabpanel" aria-labelledby="v-pills-home-tab">
                                <div class="row align-items-center">
                                    <div class="col-lg-6">
                                        <div class="appie-features-thumb text-center wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="200ms">
                                            <img src="@/assets/images/feature/feature-push-notification.png" alt=""/>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="appie-features-content wow animated fadeInRight" data-wow-duration="2000ms" data-wow-delay="600ms">
                                            <span>Custom Features & Push Functions</span>
                                            <h3 class="title">Push Notification Feature</h3>
                                            <p>Engage users effortlessly with Swing2App's push notifications. Keep them informed, connected, and engaged with instant messages and updates.</p>
                                            <a class="main-btn" href="https://documentation.swing2app.com/manual/quick-tutorial/how-to-create-push-notification" target="_blank">Learn More</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div :class="[selectedTab==='api-support'?'active show':'']"  class="tab-pane fade"  role="tabpanel" aria-labelledby="v-pills-profile-tab">
                              <div class="row align-items-center">
                                <div class="col-lg-6">
                                  <div class="appie-features-thumb text-center animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="200ms">
                                    <div>
                                      <img src="@/assets/images/feature/feature-api-support.webp" alt=""/>
                                    </div>

                                  </div>
                                </div>
                                <div class="col-lg-6">
                                  <div class="appie-features-content animated fadeInRight" data-wow-duration="2000ms" data-wow-delay="600ms">
                                    <span>Custom Features & Functions</span>
                                    <h3 class="title">API Tools Provided</h3>
                                    <p>Through the Javascript Front API and Back-end API, you can directly control native tools on the web and offer a variety of features.</p>
                                    <a class="main-btn" href="https://documentation.swing2app.com/developer/webview/javascript-api" target="_blank">Learn More</a>
                                  </div>

                                </div>
                              </div>
                            </div>
                            <div :class="[selectedTab==='admob'?'active show':'']"  class="tab-pane fade"  role="tabpanel" aria-labelledby="v-pills-profile-tab">
                                <div class="row align-items-center">
                                    <div class="col-lg-6">
                                        <div class="appie-features-thumb text-center animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="200ms">
                                          <div>
                                              <img src="@/assets/images/feature/feature-admob.png" alt=""/>
                                          </div>

                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="appie-features-content animated fadeInRight" data-wow-duration="2000ms" data-wow-delay="600ms">
                                          <span>Custom Features & Functions</span>
                                          <h3 class="title">AdMob</h3>
                                          <p>Monetize your Swing2App web app effortlessly with AdMob. Display targeted ads, earn revenue from clicks and impressions, and maximize your earnings.</p>
                                          <a class="main-btn" href="https://documentation.swing2app.com/store/admob" target="_blank">Learn More</a>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div :class="[selectedTab==='notice'?'active show':'']"  class="tab-pane fade"  role="tabpanel" aria-labelledby="v-pills-messages-tab">
                                <div class="row align-items-center">
                                    <div class="col-lg-6">
                                        <div class="appie-features-thumb text-center animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="200ms">
                                            <img src="@/assets/images/feature/feature-menu.png" alt=""/>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="appie-features-content animated fadeInRight" data-wow-duration="2000ms" data-wow-delay="600ms">
                                          <span>Custom Features & Functions</span>
                                          <h3 class="title">Custom Menu & Link</h3>
                                          <p>It's a feature that adjusts the app's structure and navigation to be user-friendly. Through flexible menu editing, it optimizes the user experience.</p>
                                          <a class="main-btn" target="_blank" href="https://documentation.swing2app.com/developer/webview/user-integration">Learn More</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div :class="[selectedTab==='lock'?'active show':'']" class="tab-pane fade"  role="tabpanel" aria-labelledby="v-pills-settings-tab">
                                <div class="row align-items-center">
                                    <div class="col-lg-6">
                                        <div class="appie-features-thumb text-center animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="200ms">
                                            <img src="@/assets/images/feature/feature-update.png" alt=""/>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="appie-features-content animated fadeInRight" data-wow-duration="2000ms" data-wow-delay="600ms">
                                            <span>Custom Features & Functions</span>
                                            <h3 class="title">Real Time <br/> Updates Feature</h3>
                                            <p>Keep your web app constantly updated with real-time content. Engage and inform your audience with seamless and timely updates.</p>
                                            <a class="main-btn" href="https://documentation.swing2app.com/manual/maual/appbasic/app-update" target="_blank">Learn More</a>
                                        </div>
                                    </div>
                                </div>
                          </div>
                          <div :class="[selectedTab==='toolbar'?'active show':'']"  class="tab-pane fade"  role="tabpanel" aria-labelledby="v-pills-profile-tab">
                            <div class="row align-items-center">
                              <div class="col-lg-6">
                                <div class="appie-features-thumb text-center animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="200ms">
                                  <div>
                                    <img src="@/assets/images/feature/feature-toolbar.png" alt=""/>
                                  </div>

                                </div>
                              </div>
                              <div class="col-lg-6">
                                <div class="appie-features-content animated fadeInRight" data-wow-duration="2000ms" data-wow-delay="600ms">
                                  <span>Custom Features & Push Functions</span>
                                  <h3 class="title">Toolbar</h3>
                                  <p>You can enable toolbar and customize its icons. These icons can be linked to various features like refresh, share, bookmark, and more on your website.</p>
                                  <a class="main-btn" href="https://documentation.swing2app.co.kr/manual/v3/webapp/pushapp-toolbar" target="_blank">Learn More</a>
                                </div>

                              </div>
                            </div>
                          </div>

                          <div :class="[selectedTab==='bookmark'?'active show':'']"  class="tab-pane fade"  role="tabpanel" aria-labelledby="v-pills-profile-tab">
                            <div class="row align-items-center">
                              <div class="col-lg-6">
                                <div class="appie-features-thumb text-center animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="200ms">
                                  <div>
                                    <img src="@/assets/images/feature/feature-bookmark.png" alt=""/>
                                  </div>

                                </div>
                              </div>
                              <div class="col-lg-6">
                                <div class="appie-features-content animated fadeInRight" data-wow-duration="2000ms" data-wow-delay="600ms">
                                  <span>Custom Features & Push Functions</span>
                                  <h3 class="title">Bookmark</h3>
                                  <p>Easily save and categorize your favorite web pages. Access your preferred content quickly and directly, without the hassle of searching.</p>
                                  <!--                                    <a class="main-btn" href="https://documentation.swing2app.co.kr/knowledgebase/admob/admob-pluginsettings" target="_blank">알아보기</a>-->
                                </div>

                              </div>
                            </div>
                          </div>



                          <div :class="[selectedTab==='push-history'?'active show':'']"  class="tab-pane fade"  role="tabpanel" aria-labelledby="v-pills-profile-tab">
                            <div class="row align-items-center">
                              <div class="col-lg-6">
                                <div class="appie-features-thumb text-center animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="200ms">
                                  <div>
                                    <img src="@/assets/images/feature/feature-push-notification-history.png" alt=""/>
                                  </div>

                                </div>
                              </div>
                              <div class="col-lg-6">
                                <div class="appie-features-content animated fadeInRight" data-wow-duration="2000ms" data-wow-delay="600ms">
                                  <span>Custom Features & Push Functions</span>
                                  <h3 class="title">Notification History</h3>
                                  <p>The notification history feature provides quick access to a log of past notifications, ensuring you never miss important updates.</p>
                                  <!--                                    <a class="main-btn" href="https://documentation.swing2app.co.kr/knowledgebase/admob/admob-pluginsettings" target="_blank">알아보기</a>-->
                                </div>

                              </div>
                            </div>
                          </div>

                    </div>
                </div>
            </div>
        </div>
        <div class="features-shape-1">
            <img src="@/assets/images/shape/shape-6.png" alt="">
        </div>
        <div class="features-shape-2">
            <img src="@/assets/images/shape/shape-7.png" alt="">
        </div>
        <div class="features-shape-3">
            <img src="@/assets/images/shape/shape-8.png" alt="">
        </div>
    </section>
    
    <!--====== APPIE FEATURES PART ENDS ======-->
</template>

<script>
export default {
    data(){
        return{
            selectedTab:'setting',
            switchPlan:true,
        }
    },
    methods:{
         selectFeature(name){
            this.selectedTab=name
        },
    }
}
</script>

<style>


</style>