<template>
  <div>
    <!--====== PRELOADER PART START ======-->

    <!-- <div class="loader-wrap">
        <div class="preloader"><div class="preloader-close">Preloader Close</div></div>
        <div class="layer layer-one"><span class="overlay"></span></div>
        <div class="layer layer-two"><span class="overlay"></span></div>
        <div class="layer layer-three"><span class="overlay"></span></div>
    </div> -->

    <!--====== PRELOADER PART ENDS ======-->

    <!--====== OFFCANVAS MENU PART START ======-->

    <sidebar-home-one
      :sidebar="sidebar"
      @toggleSidebar="toggleSidebar"
      :menuItems="navs"
    />

    <!--====== OFFCANVAS MENU PART ENDS ======-->

    <!--====== PART START ======-->

    <header-home-six @toggleSidebar="toggleSidebar" :menuItems="navs" />

    <!--====== PART ENDS ======-->

    <!--====== APPIE HERO PART START ======-->

    <hero-home-two
        offer_title="Build app in 5 minutes, no coding required."
        heading="No App?"
        more_heading="Why Not Convert Your Website into an app now!"
        description="Curious about your website as an app? Enter your URL and test."
        description2="미리보기를 제공하는 화면은 홈페이지로 앱을 만들었을때 예시 화면입니다."
        :hero_img="hero_img"
    />

    <counter-home-two
        title="Our Community"
        description="Join Our Web App Community now and enjoy Swing2App service benefits for lifetime."
    />

    <!--====== APPIE HERO PART ENDS ======-->

    <!--====== APPIE SERRVICE 2 PART START ======-->

    <services-home-two
      class="pt-90 pb-100"
      title="Why Swing2App?"
      description="Reasons to Use Swing2App? Strengths of Swing2App"
    />

    <!--====== APPIE SERRVICE 2 PART ENDS ======-->

    <!--====== APPIE FEATURES PART START ======-->

    <features-home-one class="pb-80 appie-features-6-area" />

    <!--====== APPIE FEATURES PART ENDS ======-->

    <!--====== APPIE COUNTER PART START ======-->

    <!--====== APPIE COUNTER PART ENDS ======-->

    <!--====== APPIE VIDEO PLAYER PART START ======-->

    <video-play-home-two
      title="Watch The video to learn how"
      description="Watch Web App Tutorial Video Discover effortless web app creation without coding in our tutorial video, along with its benefits."
      videoUrl="//www.youtube.com/embed/GvhfIrEt1bA?autoplay=1"
      :thumbnail="video_bg_thumb"
    />

    <!--====== APPIE VIDEO PLAYER PART ENDS ======-->

    <!--====== APPIE DOWNLOAD 3 PART START ======-->

    <download-home-three class="pb-100 pt-0" />

    <!--====== APPIE DOWNLOAD 3 PART ENDS ======-->

    <!--====== APPIE PRICING PART START ======-->

    <priceing-home-one />

    <!--====== APPIE PRICING PART ENDS ======-->

    <!--====== APPIE BLOG 3 PART START ======-->

    <blog-home-three :blogs="blogs" />

    <!--====== APPIE BLOG 3 PART ENDS ======-->

    <!--====== APPIE FOOTER PART START ======-->

    <footer-home-one />

    <!--====== APPIE FOOTER PART ENDS ======-->

    <!--====== APPIE BACK TO TOP PART ENDS ======-->
    <div class="back-to-top">
      <a href="#"><i class="fal fa-arrow-up"></i></a>
    </div>
    <!--====== APPIE BACK TO TOP PART ENDS ======-->
  </div>
</template>

<script>

import hero_img from "@/assets/images/slider-moc-2_en.png";
// import blogImgFive from "@/assets/images/blog-5.jpg";
// import blogImgSix from "@/assets/images/blog-6.jpg";
// import blogImgSeven from "@/assets/images/blog-7.jpg";
import video_bg_thumb from "@/assets/images/video-thumb-1.jpg";
import FeaturesHomeOne from "../HomeOne/FeaturesHomeOne.vue";
import FooterHomeOne from "../HomeOne/FooterHomeOne.vue";
import PriceingHomeOne from "../HomeOne/PriceingHomeOne.vue";
import SidebarHomeOne from "../HomeOne/SidebarHomeOne.vue";
import BlogHomeThree from "../HomeThree/BlogHomeThree.vue";
import DownloadHomeThree from "../HomeThree/DownloadHomeThree.vue";
import CounterHomeTwo from "../HomeTwo/CounterHomeTwo.vue";
import ServicesHomeTwo from "../HomeTwo/ServicesHomeTwo.vue";
import VideoPlayHomeTwo from "../HomeTwo/VideoPlayHomeTwo.vue";
import HeaderHomeSix from "./HeaderHomeSix.vue";

import HeroHomeTwo from "./HeroHomeTwo.vue";
// import HeroHomeSix from "./HeroHomeSix.vue";
export default {
  components: {
    SidebarHomeOne,
    HeaderHomeSix,
    // HeroHomeSix,
    HeroHomeTwo,
    ServicesHomeTwo,
    FeaturesHomeOne,
    CounterHomeTwo,
    VideoPlayHomeTwo,
    DownloadHomeThree,
    PriceingHomeOne,
    BlogHomeThree,
    FooterHomeOne,
  },
  data() {
    return {
      sidebar: false,
      video_bg_thumb: video_bg_thumb,
      hero_img: hero_img,
      blogs: [
        {
          img: "https://www.swing2app.com/blog/wp-content/uploads/sites/4/2023/05/HEADER-IMG.png",
          title: "Why You need web apps for your website",
          date: "July 14, 2022",
          comment_date: "July 14, 2022",
          link : "https://www.swing2app.com/blog/create-free-web-apps-with-swing2app-why-you-need-web-apps-for-your-website/"
        },
        {
          img: "https://www.swing2app.com/blog/wp-content/uploads/sites/4/2022/08/MicrosoftTeams-image-2022-08-12T185705.998.png",
          title: "Push Notification Explained And Why Do They Matter?",
          date: "July 14, 2022",
          comment_date: "July 14, 2022",
          link : "https://www.swing2app.com/blog/push-notification-explained-and-why-do-they-matter/"
        },
        {
          img: "https://www.swing2app.com/blog/wp-content/uploads/sites/4/2022/04/New-Year-Resolution-12.png",
          title: "Best Website To App Converter: Why Swing2App?",
          date: "July 14, 2022",
          comment_date: "July 14, 2022",
          link : "https://www.swing2app.com/blog/best-website-to-app-converter-why-should-you-choose-swing2app/"
        },
        {
          img: "https://www.swing2app.com/blog/wp-content/uploads/sites/4/2022/12/MicrosoftTeams-image-102.png",
          title: "How Can Swing2app Can Give You Long Term Advantage?",
          date: "July 14, 2022",
          comment_date: "July 14, 2022",
          link : "https://www.swing2app.com/blog/how-can-swing2app-can-give-you-long-term-advantage-that-other-companys-or-freelancers-developer-cant/"
        },
      ],
      navs: [
        {
          name: "Home",
          path: "#",
          target : "_self"
        },
        {
          name: "Service",
          childrens: [
            {
              name: "App Builder without Website",
              path: "https://www.swing2app.com/prototype",
              target : "_blank"
            },

          ]
        },
        {
          name: "Features",
          path: "#service-details",
          target : "_self"
        },
        {
          name: "Price",
          path: "#price",
          target : "_self"
        },
        {
          name: "Contact",
          path: "https://www.swing2app.com/contact",
          target : "_blank"
        }
      ],
    };
  },
  mounted() {
    document.addEventListener("scroll", this.topToBottom);
  },
  methods: {
    topToBottom() {
      const result = document.querySelector(".back-to-top");
      if (
        document.body.scrollTop > window.innerHeight ||
        document.documentElement.scrollTop > window.innerHeight
      ) {
        result.style.display = "block";
      } else {
        result.style.display = "none";
      }
    },
    toggleSidebar() {
      this.sidebar = !this.sidebar;
    },
  },
};
</script>

<style>
</style>
