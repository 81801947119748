<template>
  <!--====== APPIE FOOTER PART START ======-->

  <section class="appie-footer-area">
    <div class="container">
      <div class="row">
        <div class="col-lg-4 col-md-6">
          <div class="footer-about-widget">
            <div class="logo">
              <a href="#"><img src="@/assets/images/logo-8.png" alt="" /></a>
            </div>
            <p>
              Convert Your Website To a No-Code App with Swing2App.
            </p>

            <div class="social mt-30">
              <ul>
                <li>
                  <a href="https://www.youtube.com/@swing2app928" target="_blank"><i class="fab fa-youtube"></i></a>
                </li>
                <li>
                  <a href="https://www.swing2app.com/blog" target="_blank"><i class="far fa-blog"></i></a>
                </li>
                <li>
                  <a href="https://documentation.swing2app.com/manual/V3/webapp/webview" target="_blank"><i class="fas fa-books"></i></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-2 col-md-6">
          <div class="footer-navigation">
            <h4 class="title">Service</h4>
            <ul>
              <li><a href="https://www.swing2app.com/" target="_blank">App Production</a></li>
              <li><a href="https://www.swing2app.com/blog" target="_blank">Blog</a></li>
              <li><a href="https://www.swing2app.com/contact" target="_blank">Contact</a></li>
            </ul>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="footer-navigation">
            <h4 class="title">Support</h4>
            <ul>
              <li><a href="https://documentation.swing2app.com" target="_blank">Documentation</a></li>
              <li><a href="https://documentation.swing2app.com/FAQ" target="_blank">Faqs</a></li>
              <li><a href="https://support.swing2app.com/policy-privacy/" target="_blank">Privacy Policy</a></li>
            </ul>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="footer-widget-info">
            <h4 class="title">Get In Touch</h4>
            <ul>
              <li>
                <a href="#"
                  ><i class="fal fa-envelope"></i> help@swing2app.com</a
                >
              </li>
              <li>
                <a href="#"
                  ><i class="fal fa-map-marker-alt"></i> 2nd floor 12, Digital-ro 31-gil, Guro-gu, Seoul, Korea</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div
            class="
              footer-copyright
              d-flex
              align-items-center
              justify-content-between
              pt-35
            "
          >
            <div class="apps-download-btn">
              <ul>
<!--                <li>-->
<!--                  <a href="#"><i class="fab fa-apple"></i> Download for iOS</a>-->
<!--                </li>-->
<!--                <li>-->
<!--                  <a class="item-2" href="#"-->
<!--                    ><i class="fab fa-google-play"></i> Download for Android</a-->
<!--                  >-->
<!--                </li>-->
              </ul>
            </div>
            <div class="copyright-text">
              <p>Copyright © 2015 Swing2App. All rights reserved.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!--====== APPIE FOOTER PART ENDS ======-->
</template>

<script>
export default {};
</script>

<style>
</style>