<template>
  <!--====== APPIE HERO PART START ======-->

  <section class="appie-hero-area appie-hero-area-2 mb-90">
    <div name="modal" :class="{ 'modal-hide-el': !showModal }">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">

            <div class="modal-header">
              <slot name="header">
                QR code
              </slot>
            </div>

            <div class="modal-body">
              <slot name="body">

                <div>
                  <canvas class="qr-code-canvas" :key="componentKey"></canvas>
                </div>
                <div class="qr-info-box">
                  Scan the QR code above to check the sample screen of "{{ testUrlVal }}" as a web app.
                </div>

              </slot>
            </div>

            <div class="modal-footer">
              <slot name="footer">
                <div class="modal-close-btn" v-on:click="toggleModalFn(false)">
                  Close
                </div>
              </slot>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-5">
          <div class="appie-hero-content-2">
            <span>{{ offer_title }}</span>
            <h1 class="appie-title">{{ heading }} <span style="font-size: 40px">{{ more_heading }}</span></h1>
            <p>Curious about your website as an app?<br> Enter your URL and test.</p>
            <div>
              <div class="input-box">
                <input type="text" placeholder="https://www.swing2app.co.kr" :value="testUrlVal"
                       @input="event => testUrlVal = event.target.value" v-on:keyup.enter="toggleModalFn(true)"/>
                <i class="fas fa-link"></i>
                <button type="button" v-on:click="toggleModalFn(true)"><i class="fal fa-arrow-right"></i></button>
              </div>
            </div>
            <p>Get a glimpse of your website as an app in this preview.</p>
          </div>
        </div>
      </div>
    </div>
    <div class="appie-hero-thumb-3 wow animated fadeInRight" data-wow-duration="2000ms" data-wow-delay="400ms">
      <img :src="hero_img" alt="">
      <!--      <div :style="{ backgroundImage : `url(${require('@/assets/images/slider-moc-2_en.png')})`, width:'20vw', height: '41vw',-->
      <!--        padding: '15px', backgroundSize: 'contain',backgroundRepeat: 'no-repeat'-->
      <!--      }" @click="greet" class="iframe-area">-->
      <!--        <iframe src="http://app.swing2app.co.kr/" style="width: 100%;height: 100%"></iframe>-->
      <!--      </div>-->
    </div>
    <div class="hero-shape-1">
      <img src="@/assets/images/shape/shape-9.png" alt="">
    </div>
    <div class="hero-shape-2">
      <img src="@/assets/images/shape/shape-10.png" alt="">
    </div>
    <div class="hero-shape-3">
      <img src="@/assets/images/shape/shape-11.png" alt="">
    </div>
    <div class="hero-shape-4">
      <img src="@/assets/images/shape/shape-12.png" alt="">
    </div>
  </section>

  <!--====== APPIE HERO PART ENDS ======-->
</template>

<script>
import QRCode from "qrcode";

export default {
  props: {
    offer_title: {
      type: String,
    },
    heading: {
      type: String,
    },
    more_heading: {
      type: String,
    },
    description: {
      type: String,
    },
    description2: {
      type: String,
    },
    hero_img: {
      type: String,
    },


  },
  data() {
    return {
      componentKey: Math.floor(Math.random() * 10000000000),
      showModal: false,
      testUrlVal: ''

    };
  },
  methods: {
    openPreview: function (event) {
      alert(`Hello !`);
      // `event` is the native DOM event
      if (event) {
        alert(event.target.tagName)
      }
    },
    greet: function (event) {
      alert(`Hello ${name.value}!`)
      // `event` is the native DOM event
      if (event) {
        alert(event.target.tagName)
      }
    },
    makeQrcodeFn() {

      // check android device or ios device

      let paramUrl = encodeURIComponent('https://www.swing2app.com/app_prototype/deeplink_preview_en.jsp?lu=' + btoa(this.testUrlVal));
      let qrUrl = `https://w2ac.page.link/?link=${paramUrl}&apn=com.hustay.swing.db3eca85393934fb9af1d049bcd410d70&amv=80000`;

      var $canvas = this.$el.querySelector(".qr-code-canvas");
      QRCode.toCanvas($canvas, qrUrl, function (error) {
        if (error) console.error(error)
        console.log('success!');
      })
    },
    isValidURL(str) {
      // URL에 대한 정규 표현식 패턴
        var pattern = new RegExp('^https?:\\/\\/' + // protocol (http or https)
            '(([a-z\\d]([a-z\\d-]*[a-z\\d])*\\.)+[a-z]{2,}|' +
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // domain name OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
        return !!pattern.test(str);
    },
    toggleModalFn(p_isOpen) {
      let paramUrl = this.testUrlVal;
      if (p_isOpen === true && (!paramUrl || paramUrl.length == 0)) {
        alert("Please enter the website URL");
        return false;
      }

      if(!this.isValidURL(paramUrl))
      {
        alert('The website URL format is incorrect');
        return false;
      }


      this.showModal = (p_isOpen == true);
      if (p_isOpen === true) {
        this.makeQrcodeFn()
      }
    },
  }
}


</script>


<style>

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 330px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
  text-align: center;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}


.modal-hide-el {
  display: none !important;
}

.qr-code-canvas {
  width: 250px !important;
  height: 250px !important;
}

.qr-info-box {
  white-space: pre-wrap;
}

.modal-close-btn {
  cursor: pointer;
}

</style>
