<template>
  <div id="app">
    <home-six/>
  </div>
</template>

<script>
import HomeSix from '../components/HomeSix/index'
export default {
  components: {HomeSix  },

}
</script>

<style>

</style>
