import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeSix from '../views/HomeSix.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '',
    name: 'HomeSix',
    component: HomeSix
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  // stop stop cache
  scrollBehavior() {
      document.getElementById('app').scrollIntoView();
  }
})

export default router
