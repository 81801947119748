<template>
      <!--====== APPIE SERRVICE 2 PART START ======-->
    
    <section class="appie-services-2-area pb-100" id="service-details">
        <div class="container">
            <div class="row align-items-end">
                <div class="col-lg-6 col-md-8">
                    <div class="appie-section-title">
                        <h3 class="appie-title">{{title}}</h3>
                        <p>{{description}}</p>
                    </div>
                </div>
                <div class="col-lg-6 col-md-4">
<!--                    <div class="appie-section-title text-right">-->
<!--                        <a class="main-btn" href="#">View all Features <i class="fal fa-arrow-right"></i></a>-->
<!--                    </div>-->
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="appie-single-service-2 mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="200ms">
                        <div class="icon" >
                            <i class="fas fa-question" style="background: #3498db"></i>
                        </div>
                        <h4 class="title">Can I create an app with just a website?</h4>
                        <p>Yes, that's correct. With just a website URL, you can create an app in 5 minutes using Swing2App.</p>
<!--                        <a href="#">Read More <i class="fal fa-arrow-right"></i></a>-->
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="appie-single-service-2 item-2 mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="400ms">
                        <div class="icon">
                            <i class="fas fa-question" style="background: #e74c3c"></i>
                        </div>
                        <h4 class="title">Is there no difference between the web page and the app?</h4>
                        <p>Swing2App offers a variety of features beyond the web page and provides many more functions compared to other services. (AdMob, in-app purchases, toolbar, bookmark, push notifications, API tools, etc.)</p>
<!--                        <a href="#">Read More <i class="fal fa-arrow-right"></i></a>-->
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="appie-single-service-2 item-3 mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="600ms">
                        <div class="icon">
                            <i class="fas fa-question" style="background: #2ecc71"></i>
                        </div>
                        <h4 class="title">If I change the website, will it be automatically reflected in the app?</h4>
                        <p>You can freely modify the website at any time, and the content of the website will be reflected in real time in the app.</p>
<!--                        <a href="#">Read More <i class="fal fa-arrow-right"></i></a>-->
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="appie-single-service-2 item-4 mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="200ms">
                        <div class="icon">
                            <i class="fas fa-question" style="background: #f1c40f"></i>
                        </div>
                        <h4 class="title">Can I not use the service before making a payment?</h4>
                        <p>You can create an app and check all the features in the free version. You can use it for free as much as you need and then switch to the paid version.</p>
<!--                        <a href="#">Read More <i class="fal fa-arrow-right"></i></a>-->
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="appie-single-service-2 item-5 mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="400ms">
                        <div class="icon">
                            <i class="fas fa-question" style="background: #9b59b6"></i>
                        </div>
                        <h4 class="title">Is there a way to purchase without paying monthly fees?</h4>
                        <p>Swing2App offers an lifetime plan. If you purchase the lifetime plan once, you do not need to make any further payments.</p>
<!--                        <a href="#">Read More <i class="fal fa-arrow-right"></i></a>-->
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="appie-single-service-2 item-6 mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="600ms">
                        <div class="icon">
                            <i class="fas fa-question" style="background: #e67e22"></i>
                        </div>
                        <h4 class="title">Android + iPhone App Development</h4>
                        <p>The app you create will be developed for both Android and iPhone simultaneously. The automated production system allows the app to be completed within 5 minutes.</p>
<!--                        <a href="#">Read More <i class="fal fa-arrow-right"></i></a>-->
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                  <div class="appie-single-service-2 item-6 mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="600ms">
                    <div class="icon">
                      <i class="fas fa-question" style="background: #1abc9c"></i>
                    </div>
                    <h4 class="title">Do I have to pay based on the amount of push notifications sent?</h4>
                    <p>No, push notifications are free of charge regardless of the number of notifications sent or recipients.</p>
                    <!--                        <a href="#">Read More <i class="fal fa-arrow-right"></i></a>-->
                  </div>
                </div>

              <div class="col-lg-4 col-md-6">
                <div class="appie-single-service-2 item-6 mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="600ms">
                  <div class="icon">
                    <i class="fas fa-question" style="background: #34495e"></i>
                  </div>
                  <h4 class="title">Do I have to pay based on the amount of push notifications sent?</h4>
                  <p>No, push notifications are free of charge regardless of the number of notifications sent or recipients.</p>
                  <!--                        <a href="#">Read More <i class="fal fa-arrow-right"></i></a>-->
                </div>
              </div>

              <div class="col-lg-4 col-md-6">
                  <div class="appie-single-service-2 item-6 mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="600ms">
                    <div class="icon">
                      <i class="fas fa-question" style="background: #95a5a6"></i>
                    </div>
                    <h4 class="title">Website Member Integration? API Integration?</h4>
                    <p>You can send individual push notifications to website members or provide content that is only visible to members. With API integration, you can also send push notifications automatically without going through Swing2App.</p>
                    <!--                        <a href="#">Read More <i class="fal fa-arrow-right"></i></a>-->
                  </div>
                </div>

            </div>
        </div>
    </section>
    
    <!--====== APPIE SERRVICE 2 PART ENDS ======-->
</template>

<script>
export default {
    props:{
        title:{
            type:String,
        },
        description:{
            type:String,
        }
    }

}
</script>

<style>

</style>