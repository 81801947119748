import Vue from 'vue'
// eslint-disable-next-line no-unused-vars
import HomeSix from './views/HomeSix.vue'
import router from './router'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

import {store} from './store/index'
// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '../src/assets/css/magnific-popup.css'
import '../src/assets/css/magnific-popup.css'
import '../src/assets/css/font-awesome.min.css'
import '../src/assets/css/custom-animated.css'
import '../src/assets/css/default.css'
import '../src/assets/css/magnific.dark.css'
import '../src/assets/css/style.css'
import '../src/assets/css/main.css'
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin

Vue.use(IconsPlugin)

Vue.config.productionTip = false


window.Vue = Vue

const mainAppVueOb = new Vue({
  store,
  router,
  render: h => h(HomeSix)
}).$mount('#app')

window.mainAppVueOb = mainAppVueOb
