<template>
  <!--====== APPIE PRICING PART START ======-->
    
    <section id="price" class="appie-pricing-area pb-90">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="appie-section-title text-center">
                        <h3 class="appie-title">{{title}}</h3>
                        <p>{{description}}</p>
                    </div>
                    <ul class="list-inline text-center switch-toggler-list" role="tablist" id="switch-toggle-tab">
                        <li class="month" :class="[switchPlan?'active':'']"><a href="#">Subscription</a></li>
                        <li>
                            <!-- Rounded switch -->
                            <label class="switch" :class="[switchPlan?'on':'off']"  @click.prevent="change_plan">
                                <span class="slider round"></span>
                            </label>
                        </li>
                        <li class="year" :class="[switchPlan === false?'active':'']"><a href="#">Lifetime</a></li>
                    </ul><!-- /.list-inline -->                    
                </div>
            </div>
            <div class="tabed-content">
                <div id="month" :style="[switchPlan?{'display':'block'}:{'display':'none'}]">
                    <div class="row justify-content-center">
                        <div class="col-lg-4 col-md-6 wow animated fadeInLeft">
                            <div class="pricing-one__single">
                                <div class="pricig-heading">
                                    <h6>Free</h6>
                                    <div class="price-range"><sup>$</sup> <span>0</span><p></p></div>
                                    <p>&nbsp;</p>
                                </div>
                                <div class="pricig-body">
                                    <ul>
                                        <li><i class="fal fa-check"></i> Unlimited Push notification</li>
                                        <li><i class="fal fa-check"></i> Download APK</li>
                                        <li><i class="fal fa-check"></i> Pull to Refresh</li>
                                        <li><i class="fal fa-check"></i> Custom Progress bar Color</li>
                                        <li><i class="fal fa-check"></i> Media Upload(Camera,Album) Available</li>
                                        <li><i class="fal fa-check"></i> Custom URL Available</li>
                                        <li><i class="fal fa-check"></i> Deeplink Available</li>
                                        <li><i class="fal fa-check"></i> 10 MAU Allowed</li>
                                    </ul>
                                    <div class="pricing-btn mt-35">
<!--                                        <a class="main-btn" href="#">Choose plan</a>-->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 wow animated fadeInUp">
                            <div class="pricing-one__single center">
                                <div class="pricig-heading">
                                    <h6>Basic Plan</h6>
                                    <div class="price-range"><sup>$</sup> <span>33</span><p>/month</p></div>
                                    <p>Billed $33 per website monthly.</p>
                                </div>
                                <div class="pricig-body">
                                    <ul>
                                        <li><i class="fal fa-check"></i> Unlimited Push notification</li>
                                        <li><i class="fal fa-check"></i> Push Notification API Support</li>
                                        <li><i class="fal fa-check"></i> Download APK & AAB(Bundle)</li>
                                        <li><i class="fal fa-check"></i> Playstore upload available</li>
                                        <li><i class="fal fa-check"></i> Appstore upload available</li>
                                        <li><i class="fal fa-check"></i> Pull to Refresh</li>
                                        <li><i class="fal fa-check"></i> Custom Progress bar Color</li>
                                        <li><i class="fal fa-check"></i> Media Upload(Camera,Album) Available</li>
                                        <li><i class="fal fa-check"></i> Custom URL Available</li>
                                        <li><i class="fal fa-check"></i> Deeplink Available</li>
                                        <li><i class="fal fa-check"></i> Custom Navigation Toolbar Support</li>
                                        <li><i class="fal fa-check"></i> Custom Link Menu Support</li>
                                        <li><i class="fal fa-check"></i> Bookmark & Notification History Support</li>
                                        <li><i class="fal fa-check"></i> Unlimited MAU</li>
                                    </ul>
                                    <div class="pricing-btn mt-35">
<!--                                        <a class="main-btn" href="#">Choose plan</a>-->
                                    </div>
                                </div>
                                <div class="pricing-rebon">
                                    <span>Most Popular</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 wow animated fadeInRight">
                            <div class="pricing-one__single">
                                <div class="pricig-heading">
                                    <h6>Basic Plan 1yr</h6>
                                    <div class="price-range"><sup>$</sup> <span>290</span><p>/year</p></div>
                                    <p>Billed $290 per website annually.</p>
                                </div>
                                <div class="pricig-body">
                                    <ul>
                                        <li><i class="fal fa-check"></i> Unlimited Push notification</li>
                                        <li><i class="fal fa-check"></i> Push Notification API Support</li>
                                        <li><i class="fal fa-check"></i> Download APK & AAB(Bundle)</li>
                                        <li><i class="fal fa-check"></i> Playstore upload available</li>
                                        <li><i class="fal fa-check"></i> Appstore upload available</li>
                                        <li><i class="fal fa-check"></i> Pull to Refresh</li>
                                        <li><i class="fal fa-check"></i> Custom Progress bar Color</li>
                                        <li><i class="fal fa-check"></i> Media Upload(Camera,Album) Available</li>
                                        <li><i class="fal fa-check"></i> Custom URL Available</li>
                                        <li><i class="fal fa-check"></i> Deeplink Available</li>
                                        <li><i class="fal fa-check"></i> Custom Navigation Toolbar Support</li>
                                        <li><i class="fal fa-check"></i> Custom Link Menu Support</li>
                                        <li><i class="fal fa-check"></i> Bookmark & Notification History Support</li>
                                        <li><i class="fal fa-check"></i> Unlimited MAU</li>
                                    </ul>
                                    <div class="pricing-btn mt-35">
<!--                                        <a class="main-btn" href="#">Choose plan</a>-->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div><!-- /#month -->
                <div id="year" :style="[switchPlan=== false?{'display':'block'}:{'display':'none'}]">
                    <div class="row justify-content-center">
                        <div class="col-lg-4 col-md-6 wow animated fadeInLeft" style="margin-bottom: 20px">
                            <div class="pricing-one__single">
                                <div class="pricig-heading">
                                    <h6>Lifetime Webview Only <br>Android</h6>
                                    <div class="price-range"><sup>$</sup> <span>99</span><p>/Lifetime</p></div>
                                  <p>Billed $99 per website once</p>
                                </div>
                                <div class="pricig-body">
                                    <ul>
                                        <li><i class="fa fa-times" style="color: red"></i> Push Notification</li>
                                        <li><i class="fal fa-check"></i> Download APK & AAB(Bundle)</li>
                                        <li><i class="fal fa-check"></i> Playstore upload available</li>
                                        <li><i class="fal fa-check"></i> Pull to Refresh</li>
                                        <li><i class="fal fa-check"></i> Custom Progress bar Color</li>
                                        <li><i class="fal fa-check"></i> Media Upload(Camera,Album) Available</li>
                                        <li><i class="fal fa-check"></i> Custom URL Available</li>
                                        <li><i class="fal fa-check"></i> Deeplink Available</li>
                                        <li><i class="fal fa-check"></i> Unlimited MAU</li>
                                    </ul>
                                    <div class="pricing-btn mt-35">
<!--                                        <a class="main-btn" href="#">Choose plan</a>-->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 wow animated fadeInUp" style="margin-bottom: 10px">
                            <div class="pricing-one__single">
                                <div class="pricig-heading">
                                    <h6>Lifetime Webview Only <br>iOS</h6>
                                    <div class="price-range"><sup>$</sup> <span>230</span><p>/Lifetime</p></div>
                                    <p>Billed $230 per website once</p>
                                </div>
                                <div class="pricig-body">
                                    <ul>
                                        <li><i class="fa fa-times" style="color: red"></i> Push Notification</li>
                                        <li><i class="fal fa-check"></i> Appstore upload available</li>
                                        <li><i class="fal fa-check"></i> Pull to Refresh</li>
                                        <li><i class="fal fa-check"></i> Custom Progress bar Color</li>
                                        <li><i class="fal fa-check"></i> Media Upload(Camera,Album) Available</li>
                                        <li><i class="fal fa-check"></i> Custom URL Available</li>
                                        <li><i class="fal fa-check"></i> Deeplink Available</li>
                                        <li><i class="fal fa-check"></i> Unlimited MAU</li>
                                    </ul>
                                    <div class="pricing-btn mt-35">
<!--                                        <a class="main-btn" href="#">Choose plan</a>-->
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 wow animated fadeInRight" style="margin-bottom: 20px">
                            <div class="pricing-one__single center">
                                <div class="pricig-heading">
                                    <h6>Lifetime Webview Only <br>Android+iOS</h6>
                                    <div class="price-range"><sup>$</sup> <span>300</span><p>/Lifetime</p></div>
                                    <p>Billed $300 per website once.</p>
                                </div>
                                <div class="pricig-body">
                                    <ul>
                                        <li><i class="fa fa-times" style="color: red"></i> Push Notification</li>
                                        <li><i class="fal fa-check"></i> Download APK & AAB(Bundle)</li>
                                        <li><i class="fal fa-check"></i> Playstore upload available</li>
                                        <li><i class="fal fa-check"></i> Appstore upload available</li>
                                        <li><i class="fal fa-check"></i> Pull to Refresh</li>
                                        <li><i class="fal fa-check"></i> Custom Progress bar Color</li>
                                        <li><i class="fal fa-check"></i> Media Upload(Camera,Album) Available</li>
                                        <li><i class="fal fa-check"></i> Custom URL Available</li>
                                        <li><i class="fal fa-check"></i> Deeplink Available</li>
                                        <li><i class="fal fa-check"></i> Unlimited MAU</li>
                                    </ul>
                                    <div class="pricing-btn mt-35">
<!--                                        <a class="main-btn" href="#">Choose plan</a>-->
                                    </div>
                                </div>
                                <div class="pricing-rebon">
                                  <span>Most Popular</span>
                                </div>
                            </div>
                        </div>
                      <div class="col-lg-4 col-md-6 wow animated fadeInRight" style="margin-bottom: 10px">
                        <div class="pricing-one__single">
                          <div class="pricig-heading">
                            <h6>Lifetime Push Only <br>Android</h6>
                            <div class="price-range"><sup>$</sup> <span>250</span><p></p></div>
                            <p>Billed $250 per website once.</p>
                          </div>
                          <div class="pricig-body">
                            <ul>
                              <li><i class="fal fa-check"></i> Unlimited Push notification</li>
                              <li><i class="fal fa-check"></i> Push Notification API Support</li>
                              <li><i class="fal fa-check"></i> Download APK & AAB(Bundle)</li>
                              <li><i class="fal fa-check"></i> Playstore upload available</li>
                              <li><i class="fal fa-check"></i> Pull to Refresh</li>
                              <li><i class="fal fa-check"></i> Custom Progress bar Color</li>
                              <li><i class="fal fa-check"></i> Media Upload(Camera,Album) Available</li>
                              <li><i class="fal fa-check"></i> Custom URL Available</li>
                              <li><i class="fal fa-check"></i> Deeplink Available</li>
                              <li><i class="fal fa-check"></i> Custom Navigation Toolbar Support</li>
                              <li><i class="fal fa-check"></i> Custom Link Menu Support</li>
                              <li><i class="fal fa-check"></i> Bookmark & Notification History Support</li>
                              <li><i class="fal fa-check"></i> Unlimited MAU</li>
                            </ul>
                            <div class="pricing-btn mt-35">
<!--                              <a class="main-btn" href="#">Choose plan</a>-->
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-6 wow animated fadeInRight" style="margin-bottom: 10px">
                        <div class="pricing-one__single">
                          <div class="pricig-heading">
                            <h6>Lifetime Push Only <br>iOS</h6>
                            <div class="price-range"><sup>$</sup> <span>350</span><p></p></div>
                            <p>Billed $350 per website once.</p>
                          </div>
                          <div class="pricig-body">
                            <ul>
                              <li><i class="fal fa-check"></i> Unlimited Push notification</li>
                              <li><i class="fal fa-check"></i> Push Notification API Support</li>
                              <li><i class="fal fa-check"></i> Appstore upload available</li>
                              <li><i class="fal fa-check"></i> Pull to Refresh</li>
                              <li><i class="fal fa-check"></i> Custom Progress bar Color</li>
                              <li><i class="fal fa-check"></i> Media Upload(Camera,Album) Available</li>
                              <li><i class="fal fa-check"></i> Custom URL Available</li>
                              <li><i class="fal fa-check"></i> Deeplink Available</li>
                              <li><i class="fal fa-check"></i> Custom Navigation Toolbar Support</li>
                              <li><i class="fal fa-check"></i> Custom Link Menu Support</li>
                              <li><i class="fal fa-check"></i> Bookmark & Notification History Support</li>
                              <li><i class="fal fa-check"></i> Unlimited MAU</li>
                            </ul>
                            <div class="pricing-btn mt-35">
<!--                              <a class="main-btn" href="#">Choose plan</a>-->
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-6 wow animated fadeInRight" style="margin-bottom: 10px">
                        <div class="pricing-one__single center">
                          <div class="pricig-heading">
                            <h6>Lifetime Push Only <br>Android+iOS</h6>
                            <div class="price-range"><sup>$</sup> <span>550</span><p></p></div>
                            <p>Billed $550 per website once.</p>
                          </div>
                          <div class="pricig-body">
                            <ul>
                              <li><i class="fal fa-check"></i> Unlimited Push notification</li>
                              <li><i class="fal fa-check"></i> Push Notification API Support</li>
                              <li><i class="fal fa-check"></i> Download APK & AAB(Bundle)</li>
                              <li><i class="fal fa-check"></i> Playstore upload available</li>
                              <li><i class="fal fa-check"></i> Appstore upload available</li>
                              <li><i class="fal fa-check"></i> Appstore Review Management</li>
                              <li><i class="fal fa-check"></i> Pull to Refresh</li>
                              <li><i class="fal fa-check"></i> Custom Progress bar Color</li>
                              <li><i class="fal fa-check"></i> Media Upload(Camera,Album) Available</li>
                              <li><i class="fal fa-check"></i> Custom URL Available</li>
                              <li><i class="fal fa-check"></i> Deeplink Available</li>
                              <li><i class="fal fa-check"></i> Custom Navigation Toolbar Support</li>
                              <li><i class="fal fa-check"></i> Custom Link Menu Support</li>
                              <li><i class="fal fa-check"></i> Bookmark & Notification History Support</li>
                              <li><i class="fal fa-check"></i> Unlimited MAU</li>
                            </ul>
                            <div class="pricing-btn mt-35">
<!--                              <a class="main-btn" href="#">Choose plan</a>-->
                            </div>
                          </div>
                          <div class="pricing-rebon">
                            <span>Most Popular</span>
                          </div>
                        </div>
                      </div>

                    </div>
                </div>
            </div>
        </div>
    </section>
    
    <!--====== APPIE PRICING PART ENDS ======-->
</template>

<script>
export default {
    props:{
        title:{
            type: String,
        },
        description:{
            type: String,
        }
    },
    data(){
        return{
            switchPlan:true
        }
    },
    methods:{
         change_plan(){
            this.switchPlan = !this.switchPlan
        },
    }

}
</script>

<style>

</style>