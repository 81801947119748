<template>
    <ul>
        <li v-for="(item, index) in menuItems" :key="index">


            <a style="text-transform: capitalize;" v-if="item.path" :href="item.path" :target="item.target" >
              {{ item.name }}
            </a>

            <a v-else href="#" style="text-transform: capitalize;">{{item.name}} <i v-if="nasted" class="fal fa-angle-down"></i></a>
            <ul v-if="item.childrens && nasted" class="sub-menu">
                <li v-for="(subItem, index) in item.childrens" :key="index">
                    <a :href="subItem.path?subItem.path:'#'" style="text-transform: capitalize;" >{{subItem.name}}</a>
                </li>
            </ul>
        </li>
    </ul>
</template>

<script>
export default {
    props:{
        menuItems: {
            type:Array,
            required:true,
        },
         nasted:{
            type:Boolean,
            default:true
        }
    }

}
</script>

<style>

</style>